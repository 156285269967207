.pill-container {
  position: relative;
}
.pill-container .pill {
  width: 320px;
  height: 230px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 15px;
  position: relative;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #dcdcdc;
  transition: 0.2s all;
  overflow: hidden;
}
.pill-container .pill .indicator {
  width: 90px;
  height: 95px;
  border-radius: 0px 5px 0px 0px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "Grueber", sans-serif;
  border: 1px solid #dcdcdc;
  font-size: 20px;
  float: right;
}
.pill-container .pill .text {
  display: inline-block;
  vertical-align: top;
  padding-left: 15px;
  padding-top: 15px;
  max-width: 200px;
}
.pill-container .pill .text .pill-title {
  font-family: "Bree Serif", sans-serif;
  font-size: 16px;
  margin: 0px 0px 10px 0px;
}
.pill-container .pill .text .pill-desc {
  font-size: 14px;
  color: #999;
  margin: 0px;
  height: 35px;
}
.pill-container .pill .text .pill-status {
  font-size: 14px;
}
.pill-container .pill .pill-targets {
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
  width: 100%;
  position: relative;
}
.pill-container .pill .pill-targets .target-row {
  border-top: 1px solid #ddd;
  width: 100%;
  color: #555;
  position: relative;
}
.pill-container .pill .pill-targets .target-row .target-name {
  display: inline-block;
  position: relative;
  left: 20px;
  width: 224px;
  padding: 12px 0px 12px 15px;
}
.pill-container .pill .pill-targets .target-row .check-mark {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  top: 12px;
  left: 10px;
  position: absolute;
  color: #333;
}
.pill-container .pill .pill-targets .target-row .check-mark .tick {
  font-size: 18px;
  position: relative;
  top: -6px;
  left: 1px;
  color: #555;
}
.pill-container .pill .pill-targets .target-row .target-level {
  display: inline-block;
  text-align: right;
  width: 56px;
  padding: 12px 15px 12px 0px;
}
.pill-container .pill.closed {
  height: 98px;
  overflow: hidden;
  transition: 0.2s all;
}
.pill-container .help-wrap {
  position: absolute;
  float: right;
  right: 110px;
  top: 10px;
  z-index: 1;
}/*# sourceMappingURL=StatusPill.css.map */