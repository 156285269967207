@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@font-face {
    font-family: "Grueber";
    src: url('Grueber_Rg.ttf');
}

$HLcharcoal: rgb(52, 51, 52);
$HLpurple: rgb(166,126,198);
$HLpaleGreen: rgb(181, 209, 192);
$HLblue: rgb(129,176,192);
$HLred: rgb(208,46,111);
$HLyellow: rgb(227, 237, 100);
$HLgrey: rgba(180,180,180);
$HLlightGrey: rgba(220,220,220);

$boxshadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
$dash-border-radius:5px;

body {
    font-family: 'Lato', sans-serif;
    margin: 0;;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: linear-gradient(
          rgba(0, 0, 0, 0.6), 
          rgba(0, 0, 0, 0.6)
        ),
        url('../../public/media/splash.jpg');
    background-size:cover;
    background-repeat:no-repeat;
    background-position: 50% 50%;
    background-attachment: fixed;
  }
  
::-webkit-scrollbar {
    width: 10px;
    height:10px;
    border-radius:5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
// border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #aaa;
// border-radius:10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #888;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
  

.content-splash{
    width:100%;
    height:100%;
    position:fixed;
    padding:0px;
    margin:0px;
    background: linear-gradient(
          rgba(0, 0, 0, 0.5), 
          rgba(0, 0, 0, 0.5)
        ),
        url('../../public/media/splash.jpg');
    background-size:cover;
    background-repeat:no-repeat;
    background-position: 50% 50%;
    .login-wrapper {
        position: fixed;
        left:70px;
        top:150px;
        height:400px;
        width:250px;
        background-color: #efefef;
        border-radius:15px 15px 0px 0px;
        padding:20px 30px 30px 30px;
        -webkit-box-shadow: 15px 15px 30px 0px rgba(0,0,0,0.37);
        -moz-box-shadow: 15px 15px 30px 0px rgba(0,0,0,0.37);
        box-shadow: 15px 15px 30px 0px rgba(0,0,0,0.37);
        .bottom{
            background-color: $HLcharcoal;
            width:100%;
            padding:20px 30px 30px 30px;
            height:50px;
            left:-30px;
            position: relative;
            border-radius: 0px 0px 15px 15px;
        }
        .title{
            font-family: 'Grueber', serif;
            font-size: 40px;
            margin:0px;
            margin-top:20px;
        }
        .desc{
            font-size:0.9em;
            text-align: left;
            margin-bottom:45px;
        }

        .subtitle{
            margin-bottom:3px;
            margin-top:15px;
            font-family: 'Lato', sans-serif;
            font-size:0.9em;
            color:#333;
        }

        h3{
            font-family: 'Lato', sans-serif;
            font-size:1.2em;
        }

        .button-wrap{
            text-align: center;
            margin:30px auto;
            width:100px;
            button{
                border-radius:5px;
                font-size: 15px;
                border:0px none;
                padding:10px 10px;
                font-family: 'Bree Serif', serif;
                transition: .1s all;
                display: block;
                margin-bottom:8px;
                width:100px;
                background-color: #ddd;
                cursor: pointer;
            }
            button:hover{
                background-color: rgb(129,176,192);
                transition: .1s all;
                color:#fff;
            }
        }
        .input-wrap{
            width:90%;
        }
        input{
            width:90%;
            background: #ddd;
            height:20px;
            padding:10px 10px;
            border-radius: 10px;
            outline:none;
            border: 0px none;
            font-size:0.9em;
        }
        input:active{
            outline:none;
        }

        .HL-logo{
            margin-top:10px;
            margin-left:50px;
        }
        
    }
    .message{
        position: fixed;
        left:70px;
        top:150px;
        height:450px;
        width:250px;
        background-color: #efefef;
        border-radius:15px;
        padding:20px 30px 30px 30px;
        -webkit-box-shadow: 15px 15px 30px 0px rgba(0,0,0,0.37);
        -moz-box-shadow: 15px 15px 30px 0px rgba(0,0,0,0.37);
        box-shadow: 15px 15px 30px 0px rgba(0,0,0,0.37);
        .text{
            font-size:1em;
            color:#333;
            margin:30px 0px 0px 0px;
        }
    }
}

.background{
    .wrapper{
        width:1000px;
        min-height: 100vh;
        margin:0px auto 0px auto;
        // background-color: #efefef;
        background-color: #f7f7f7;
        padding:120px 100px 80px 100px;
        .title{
            font-family: 'Grueber', serif;
            font-size:3em;
            margin:0px;
            color:$HLcharcoal
        }
        .subtitle{
            font-family: 'Grueber', serif;
            font-size:2em;
            color:#888;
            margin:0px;
        }
        .project-description{
            font-size:1.2em;
            color:#555;
        }
        .help-text{
            font-size:14px;
            color:#777;
            margin:0px;
            margin-bottom:0px;
            max-width:400px;
        }
        .section-title{
            font-family: 'Grueber', serif;
            font-size:1.2em;
            color:#333;
            margin-bottom:10px;
        }
        .legend-wrap{
            // top:50px;
            margin-top:20px;
            width:calc(100% - 40px);
            position: relative;
        }
    }
    .about{
        .subtitle{
            color:#333;
            font-size:1.5em;
            margin-top:30px;
            margin-bottom: 15px;
        }
        .main-text{
            line-height:1.4;
            width:750px;
        }
        .about-text{
            line-height:1.8;
            margin-bottom: 50px;
        }
    }
    .glossary{
        .subtitle{
            color:#333;
            font-size:1.5em;
            margin-top:50px;
            margin-bottom: 0px;
        }
        .source{
            color:#999;
            font-size:0.9em;
            margin-top:0px;
            margin-bottom: 5px;
        }
        .main-text{
            line-height:1.4;
            width:750px;
            margin-top:5px; 
            margin-bottom:5px;
        }
        a{
            text-decoration: none;
            color: #999;
        }
        a:hover{
            color:#333;
        }
    }
    .configuration{
        .warning{
            background-color: rgb(219, 108, 108);
            border-radius: 5px;
            padding:30px;
            margin-bottom:30px;
            text-align: center;
            .warning-title{
                font-size:25px;
                font-family: 'Grueber', serif;
            }
            .warning-text{
                margin:10px auto;
                width:400px;
            }
        }
        .subtitle{
            color:#333;
            font-size:1.5em;
            margin-top:20px;
        }
        button{
            border-radius:5px;
            font-size: 15px;
            border:0px none;
            padding:10px 10px;
            font-family: 'Bree Serif', serif;
            transition: .1s all;
            display: block;
            margin-bottom:8px;
            width:150px;
            background-color: rgb(230,230,230);
            transition: .2s all;
            cursor: pointer;
        }
        button:hover{
            background-color: rgb(129,176,192);
            transition: .1s all;
            color:#fff;
        }
        .normal{
            background-color: rgb(230,230,230);
            transition: .2s all;
        }
        .activated{
            background-color: rgb(132, 211, 108);
            transition: .2s all;
        }
        .activated:hover{
            background-color: rgb(132, 211, 108);
            color:#333;
            transition: .2s all;
        }
        .download-wrapper{
            margin:40px auto 10px auto;
            width:150px;
        }
        .button-wrapper{
            float:right;
        }
        
        .input-wrapper{
            margin-bottom:50px;
            display: inline-block;
            vertical-align: top;
            .input-col{
                display: inline-block;
            }
            
        }

        .dropdown{
            margin-top:10px;
            font-size:15px;
            border-radius:5px;
            border:0px none;
            width:265px;
            margin-bottom:20px;
        }
        .kpi-wrapper{
            display: inline-block;
            margin-bottom:50px;
        }
    }
    .plot-card-wrapper{
        margin-top:50px;
        display:grid;
        grid-template-columns: auto auto auto;
    }
    .plot-title{
        font-size:35px;
        font-family: 'Grueber', serif;
        margin:70px 0px 0px 0px;
    }
    .plot-table-wrap{
        margin-top:10px;
        width:fit-content;
        $cellWidth:80px;
        $cellHeight:40px;
        $cellPadding:10px 15px;  
        $longTrans: .2s;
        $shortTrans: .2s;
        display:inline-block;
        .cell{
            margin:1px;
            vertical-align: top;
            text-align: center;
            height:$cellHeight;
            width: $cellWidth;
            padding:$cellPadding;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            transition: background-color .3s;
        }
        .first-column{
            text-align: left;
            justify-content: left;
            width:150px;
            font-weight:600;
        }
        .header-row{
            display:inline-block;
            .header-cell{
                background-color: #ddd;
                color:#333;
                font-size:12px;
                font-family: 'Lato', sans-serif;
                font-weight: 600;
                height:$cellHeight+20px;
            }
        }
        .content-row{
            height:53px;
            overflow:hidden;
            display: flex;
            vertical-align: top;
            font-size:15px;
            margin:0px 0px;
            transition: margin .2s;
            .cell{
                height:30px;
                background-color: #fff;
                position: relative;
                .arrow{
                    float:right;
                    border-right: 1px solid #555;
                    border-top: 1px solid #555;
                    width:7px;
                    height:7px;
                    top:24px;
                    right:35px;
                    position:absolute;
                    transform: rotateY(0deg) rotate(45deg) translateY(-50%);
                    transition: .2s all;
                }
                .indicator{
                    width:10px;
                    height:100%;
                    position:absolute;
                    left:0px;
                }
            }
            .cell:hover{
                .arrow{
                    right:25px;
                }
            }
            .update{
                font-size:13px;
                color:#999;
            }
            a{
                color:#333;
            }
        }
        .content-row:hover{
            .cell{
                background-color: #eee !important;
                transition: background-color .2s;
            }
        }
        .break-row{
            display:flex;
            justify-content: center;
            align-items: center;
            height:40px;
            background-color: #fff;
            margin:1px 0px 2px 0px;
            border-radius: 3px;
            font-weight:600;
        }
    }
    .dashboard{
        position: relative;
        .section-title{
            font-family: 'Grueber', serif;
            font-size:1.5em;
            color:#333;
        }

        .meta-info-wrap{
            margin:20px 0px 30px 0px;
            .meta-col{
                display: inline-block;
                margin-right:50px;
            }
            .meta-title{
                margin:0px;
                font-size:14px;
                color:#aaa;
                position: relative;
            }
            .meta-info{
                margin:10px 0px 0px 0px;
                font-size:20px;
                font-family:'Grueber', serif;
                color: #555;
            }
        }

        .showTargets-toggle{
            float:right;
            position: absolute;
            top:30px;
            right:20px;
        }

        .pill-header-wrap{
            .pill-header{
                font-family: 'Bree Serif', serif;
                font-size:1.2em;
                margin-bottom:10px;
                color:#333;
            }
            .desc{
                font-size:14px;
                color:#777;
                margin:0px;
                margin-bottom:25px;
                max-width:700px;
            }
            max-width:400px;
        }
       

        .status-pills{
            display:grid;
            grid-template-columns: auto auto auto;
            column-gap:18px;
            margin-top:20px;
        }

        .table-wrapper, .plot-budget-wrapper{
            width:940px;
            padding:30px;
            border-radius:$dash-border-radius;
            background-color: #fff;
            border:1px solid #dcdcdc;
            margin-bottom:15px;
            box-shadow: $boxshadow;
            .options-table-title{
                font-family: 'Bree Serif', serif;
                font-size:1.2em;
                margin-bottom:10px;
                color:#333;
            }
            .desc{
                font-size:14px;
                color:#999;
                margin:0px;
                margin-bottom:25px;
            }
            .plot-comparison-chart{
                height:160px;
            }
        }

        .plot-budget-wrapper{
            // margin-top:30px;
            margin-bottom:50px;
            width:940px;
            
            .desc{
                font-size:14px;
                color:#999;
                margin:0px;
            }
        }
        .calculator{
            .sidebar{
                max-height:85vh;
                overflow-y: auto;
                width:360px;
                padding:0px 0px;
                border-radius: $dash-border-radius;
                border:1px solid #dcdcdc;
                background-color: #fff;
                box-shadow: $boxshadow;
                position:sticky;
                display:inline-block;
                top:5rem;
                .subsection{
                    max-height:800px;
                    overflow: hidden;
                    padding:10px 30px 0px 30px;
                    top:-2px;
                    position:relative;
                    border-top:1px #ddd solid;
                    transition: .3s all;
                    .title-row{
                        cursor: pointer;
                        user-select: none;
                        padding:10px 0px 20px 0px;
                        .sidebar-title{
                            font-family: 'Bree Serif', serif;
                            font-size:1.2em;
                            margin-bottom:20px;
                            
                            color:#333;
                        }
                        .arrow{
                            float:right;
                            border-right: 2px solid #555;
                            border-top: 2px solid #555;
                            width:7px;
                            height:7px;
                            top:-27px;
                            right:15px;
                            position:relative;
                            transform: rotateY(0deg) rotate(-45deg) translateY(-50%);
                            transition: .2s all;
                        }
                    }
                    
                    .sidebar-subtitle{
                        font-size:13px;
                        margin-bottom:10px;
                        font-weight: bold;
                        color:#666;
                    }
                    .sidebar-description{
                        font-size:13px;
                        color:#999;
                        margin-bottom:20px;
                    }
                    .slider-section{
                        margin-bottom:30px;
                    }
                    
                }
                .subsection.closed{
                    max-height:60px;
                    overflow: hidden;
                    transition: .3s all;
                    .title-row{
                        .arrow{
                            top:-38px;
                            right:20px;
                            transform: rotateY(0deg) rotate(135deg) translateY(-50%);
                            transition: .2s all;
                        }
                    }
                }
            }
            .results-wrap{
                width:620px;
                height:auto;
                vertical-align: top;
                display:inline-block;
                .results-pill{
                    vertical-align: top;
                    position:relative;
                    width:560px;
                    padding:30px;
                    border-radius: $dash-border-radius;
                    box-shadow: $boxshadow;
                    display:inline-block;
                    background-color: #fff;
                    border:1px solid #dcdcdc;
                    margin-left:15px;
                    margin-bottom:15px;
                    .results-pill-title{
                        font-family: 'Bree Serif', serif;
                        font-size:1.2em;
                        margin-bottom:10px;
                        color:#333;
                    }
                    .desc{
                        font-size:14px;
                        color:#999;
                        margin:0px;
                        margin-bottom:25px;
                    }
                    .budget-chart-wrap{
                        height:250px;
                    }
                    .graph-year-wrap{
                        float:right;
                        right:40px;
                        top:20px;
                        position: absolute;
                        color:#ccc;
                        .year-tag{
                            display: inline-block;
                            margin-right: 5px;
                        }
                        .currency{
                            display: inline-block;
                            color:#999;
                        }
                    }
                    .apexcharts-data-labels > text{
                        font-family: "Lato", serif !important;
                        color:#333 !important;
                        font-weight:400 !important;
                        font-size:13px;
                    }
                }
                
                .results-pill.touching{
                    margin-bottom:3px !important;
                }
                .results-pill-wrap{
                    vertical-align: top;
                    width:600px;
                    margin-left:15px;
                    margin-bottom:15px;
                    display:grid;
                    grid-template-columns: auto auto auto;
                    .grey{
                        background-color: #f8f8f8 !important;
                        color:#999 !important;
                    }
                    .mini-results-pill{
                        padding:20px 40px;
                        width:125px;
                        height:100px;
                        margin-right:3px;
                        vertical-align: top;
                        border-radius: $dash-border-radius;
                        box-shadow: $boxshadow;
                        background-color: #fff;
                        user-select: none;
                        p{
                            margin:0px;
                        }
                        .subtitle{
                            font-size:12px;
                            margin-bottom:5px;
                            color:#999;
                            border-bottom: 1px solid #ddd;
                        }
                        .currency{
                            text-align: right;
                            margin-bottom:10px;
                        }
                        .main{
                            font-size:1.5em;
                            margin-top:20px;
                            font-weight:bold;
                        }
                        .lower{
                            margin-top:17px;
                        }
                    }
                }
            }
        }
    }
}