@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap");
.header {
  color: #fff;
  width: 100%;
  background-color: rgb(52, 51, 52);
  padding: 10px 30px 5px 30px;
  height: 50px;
  position: fixed;
  z-index: 3;
  text-align: left;
}
.header .title {
  line-height: normal;
  display: inline-block;
  font-family: "Grueber", serif;
  margin: 0px;
  margin-top: 2px;
  font-weight: 400;
}
.header .navbar a {
  color: #999;
  text-decoration: none;
}
.header .navbar {
  margin: 15px 0px 0px 20px;
  display: inline-block;
  vertical-align: top;
  padding: 0px;
}
.header .nav-link {
  margin-left: 40px;
  display: inline-block;
  vertical-align: top;
  font-size: 0.9em;
  color: #999;
  transition: 0.2s all;
}
.header .nav-link:hover {
  color: #fff;
  transition: 0.2s all;
}
.header .header-logo {
  float: right;
  margin-top: 7px;
  margin-right: 50px;
}
.header .logout-button {
  font-size: 0.9em;
  float: right;
  margin-right: 30px;
  margin-top: 7px;
  padding: 8px 15px;
  border-radius: 5px;
  color: #999;
  background-color: rgb(62, 61, 62);
  transition: 0.2s all;
  cursor: pointer;
}
.header .logout-button:hover {
  background-color: #666;
  color: #fff;
  transition: 0.2s all;
}/*# sourceMappingURL=Header.css.map */