.options-table-wrapper {
  margin-top: 30px;
}
.options-table-wrapper .cell {
  margin: 1px;
  vertical-align: top;
  text-align: center;
  height: 40px;
  width: 80px;
  padding: 10px 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
.options-table-wrapper .first-column {
  text-align: left;
  justify-content: left;
  width: 100px;
}
.options-table-wrapper .header-row .header-cell {
  background-color: #ddd;
  color: #333;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  height: 50px;
}
.options-table-wrapper .content-row {
  height: 53px;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}
.options-table-wrapper .content-row .default {
  color: #888;
  margin-left: 5px;
}
.options-table-wrapper .content-row .content-cell {
  background-color: #eee;
  font-size: 14px;
  height: 30px;
}
.options-table-wrapper .content-row .results {
  display: inline-block;
  font-size: 15px;
  vertical-align: top;
}
.options-table-wrapper .content-row .results .cell {
  height: 30px;
  position: relative;
  background-color: #f6f6f6;
}
.options-table-wrapper .content-row .results .cell .indicator {
  position: absolute;
  width: 10px;
  left: 0px;
  height: 100%;
}
.options-table-wrapper .edit-wrap {
  display: inline-block;
  vertical-align: top;
  position: relative;
  z-index: 1;
}
.options-table-wrapper .edit-wrap .edit-cell {
  font-family: "Bree Serif", serif;
  background-color: #ddd;
  font-size: 12px;
  width: 30px;
  height: 30px;
  transition: 0.1s all;
  cursor: pointer;
}
.options-table-wrapper .edit-wrap .button-column {
  max-height: 350px;
  padding: 10px 10px 2px 10px;
  overflow: show;
  opacity: 1;
  transition: padding 0.2s, max-height 0.2s, top 0.2s, right 0.2s;
  transition: opacity 0.2s, overflow 0.2s;
  float: right;
  position: absolute;
  top: -40px;
  right: 60px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
}
.options-table-wrapper .edit-wrap .button-column button {
  border-radius: 5px;
  border: 0px none;
  padding: 10px 10px;
  font-family: "Bree Serif", serif;
  transition: 0.1s all;
  display: block;
  margin-bottom: 8px;
  width: 100px;
  cursor: pointer;
}
.options-table-wrapper .edit-wrap .button-column .delete:hover {
  background-color: rgb(52, 51, 52);
  color: #eee;
}
.options-table-wrapper .edit-wrap .button-column button:hover {
  background-color: rgb(129, 176, 192);
  transition: 0.1s all;
  color: #fff;
}
.options-table-wrapper .edit-wrap.closed .edit-cell {
  background-color: #eee;
  transition: 0.1s all;
}
.options-table-wrapper .edit-wrap.closed .button-column {
  max-height: 0px;
  top: -50px;
  overflow: hidden;
  opacity: 0;
  transition: padding 0.2s, max-height 0.2s, top 0.2s, right 0.2s;
  transition: opacity 0.2s, overflow 0.2s;
  padding: 0px 10px 0px 10px;
}/*# sourceMappingURL=OptionsTable.css.map */