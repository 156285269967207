.legend {
  vertical-align: top;
  position: relative;
  width: calc(100% - 20px);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border: 1px solid #dcdcdc;
  margin-bottom: 15px;
}
.legend .legend-item-wrap {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.legend .legend-item-wrap .item {
  margin: 10px;
  display: inline-flex;
  align-items: center;
}
.legend .legend-item-wrap .item .square {
  height: 20px;
  width: 20px;
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.legend .legend-item-wrap .item .label {
  font-size: 13px;
  color: #555;
  display: inline-block;
  margin-left: 15px;
}
.legend .legend-title {
  font-family: "Grueber", serif;
  font-size: 20px;
  margin: 0px;
  margin-bottom: 10px;
}/*# sourceMappingURL=Legend.css.map */