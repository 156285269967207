.save-wrapper{
    .option-name-input{
        width:250px;
        outline:none;
        border:0px none;
        border-bottom:1px solid #ccc;
        font-size:16px;
        padding-bottom:5px;
        font-family: 'Lato', sans-serif;
        margin-top:10px;
        margin-right:50px;
    }
    button{
        float:right;
        border-radius:5px;
        border:0px none;
        padding:10px 10px;
        font-family: 'Bree Serif', serif;
        transition: .1s all;
        cursor: pointer;
    }

    button:hover{
        background-color: rgb(129,176,192);
        transition: .1s all;
        color:#fff;
    }

    .checkbox-label{
        display: inline-block;
        margin-right:10px;
        font-size:14px;
        color:#aaa;
        vertical-align: top;
        margin-top:3px;
    }

    /* Customize the label (the container) */
    .container {
        display: inline-block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: top;
        margin-top:6px;
    }
    
    /* Hide the browser's default checkbox */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    
    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
        border-radius:5px;
    }
    
    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
        background-color: #ccc;
        transition: .2s all;
    }
    
    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
        background-color: rgb(129,176,192);
        transition: .2s all;
    }
    
    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    
    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
        display: block;
    }
    
    /* Style the checkmark/indicator */
    .container .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    $placeholder-color:#ccc;
    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color:    $placeholder-color;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
       color:    $placeholder-color;
       opacity:  1;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
       color:    $placeholder-color;
       opacity:  1;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
       color:    $placeholder-color;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
       color:    $placeholder-color;
    }
    
    ::placeholder { /* Most modern browsers support this now. */
       color:    $placeholder-color;
    }
}