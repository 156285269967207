.warning-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
}
.warning-wrapper .popup {
  background-color: #fff;
  width: 300px;
  padding: 20px 50px;
  height: 400px;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
}
.warning-wrapper .popup .main-title {
  font-size: 30px;
  font-family: "Bree Serif", serif;
  color: #333;
  width: 100%;
  text-align: center;
  display: block;
}
.warning-wrapper .popup .message {
  display: block;
  text-align: center;
  width: 100%;
}
.warning-wrapper .popup .button-wrap {
  margin-top: 40px;
}
.warning-wrapper .popup .button-wrap button {
  border-radius: 5px;
  border: 0px none;
  padding: 15px 10px;
  font-family: "Bree Serif", serif;
  font-size: 20px;
  transition: 0.1s all;
  display: block;
  margin-bottom: 15px;
  width: 100%;
  cursor: pointer;
}
.warning-wrapper .popup .button-wrap .delete:hover {
  background-color: rgb(52, 51, 52);
  color: #ddd;
}
.warning-wrapper .popup .button-wrap button:hover {
  background-color: rgb(129, 176, 192);
  transition: 0.1s all;
  color: #fff;
}/*# sourceMappingURL=Warning.css.map */