.toggle-wrap .toggle-back {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 19px;
  vertical-align: top;
}
.toggle-wrap .toggle {
  opacity: 0;
  width: 0;
  height: 0;
}
.toggle-wrap span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #999;
  transition: 0.3s;
  border-radius: 30px;
}
.toggle-wrap span:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 2.6px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s;
}
.toggle-wrap input:checked + span {
  background-color: #333;
}
.toggle-wrap input:checked + span:before {
  transform: translateX(20px);
}
.toggle-wrap .toggle-label {
  display: inline-block;
  position: relative;
  font-size: 14px;
  color: #aaa;
  vertical-align: top;
  margin-top: 0px;
  top: 0px;
  right: 95px;
  width: 100px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}/*# sourceMappingURL=InputToggle.css.map */