// @import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');

.plot-card{
    height:340px;
    width:280px;
    background-color: #fff;
    border-radius: 8px;
    vertical-align: top;
    display: inline-block;
    margin:10px 10px 40px 10px;
    box-shadow:2px 2px 10px 0px rgba(0, 0, 0, 0);
    transition: box-shadow .2s;
    a{
        text-decoration: none;
    }
    .plot-name{
        height:35px;
        width:calc(100% - 40px);
        padding:20px 30px;
        border-bottom: 2px solid #efefef;
        cursor: pointer;
        user-select: none;
        .title{
            font-family: 'Bree Serif', serif;
            font-size: 24px;
            height:30px;
        }
        .arrow{
            float:right;
            border-right: 2px solid #555;
            border-top: 2px solid #555;
            width:7px;
            height:7px;
            top:-40%;
            right:25px;
            position:relative;
            transform: rotateY(0deg) rotate(45deg) translateY(-50%);
            transition: .2s all;
        }
    }
    .plot-name:hover{
        .arrow{
            right:20px;
        }
    }
    .trackers{
        .tracker-row{
            height:40px;
            border-bottom:2px solid #efefef;
            background-color: #fcfcfc;
            // display:flex;
            align-items: center;
            .name{
                padding:10px 10px 0px 20px;
                margin:0px;
                display: inline-block;
                vertical-align: top;
                font-size: 15px;
            }
            .data-value{
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width:50px;
                height:100%;
            }
            // .indicator{
            //     vertical-align: top;
            //     height:20px;
            //     width:20px;
            //     // border-radius: 10px;
            //     background-color: #DE5D5D;
            //     display: inline-block;
            //     text-align: center;
            // }
            .indicator{
                vertical-align: top;
                height:calc(100% - 20px);
                position: relative;
                right:0px;
                width:50px;
                background-color: #DE5D5D;
                float:right;
                padding:10px 10px 10px 10px;
                text-align: center;
            }
        }
    }
}

.plot-card:hover{
    box-shadow:2px 2px 10px 0px rgba(0, 0, 0, 0.1);
    transition: box-shadow .2s;
}