$boxshadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
$dash-border-radius:5px;

.legend{
    vertical-align: top;
    position:relative;
    width:calc(100% - 20px);
    padding:10px;
    border-radius: $dash-border-radius;
    box-shadow: $boxshadow;
    background-color: #fff;
    border:1px solid #dcdcdc;
    margin-bottom:15px;
    
    .legend-item-wrap{
        display:grid;
        grid-template-columns: auto auto auto auto;
        .item{
            margin:10px;
            display: inline-flex;
            align-items: center;
            .square{
                height:20px;
                width:20px;
                display: inline-block;
                border:1px solid #ddd;
                border-radius: 5px;
            }
    
            .label{
                font-size: 13px;
                color:#555;
                display: inline-block;
                margin-left:15px;
    
            }
        }
    }
    .legend-title{
        font-family: 'Grueber', serif;
        font-size: 20px;
        margin:0px;
        margin-bottom:10px;
    }
    
 }