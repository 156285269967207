.slider-wrapper{
    margin-bottom:20px;
    .slider-title{
        font-size:14px;
        margin-bottom:5px;
        user-select: none;
    }
    .slider{
        width:70%;
        // margin-left:20px;
        display: inline-block;
        .single-thumb .range-slider__thumb[data-lower] {
            width: 0;
        }
        .single-thumb .range-slider__thumb[data-upper] {
            width: 30px;
            height:17px;
            border-radius:7px;
            background-color: #888;
            transition: background-color .2s ;
        }
        .single-thumb .range-slider__range{
            background-color:#ddd;
            height:5px;
        }
        .single-thumb .range-slider__thumb[data-upper]:hover{
            background-color: rgb(129,176,192);
            transition: background-color .2s;
        }
    }
    .value{
        float:right;
    }
    .value{
        width:40px;
        outline:none;
        border:0px none;
        border-bottom:1px solid #ccc;
        font-size:12px;
        padding-bottom:5px;
        font-family: 'Lato', sans-serif;
        text-align: right;
    }
}

.range-slider{
    background-color:#eee;
}