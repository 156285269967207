.save-wrapper {
  /* Customize the label (the container) */
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}
.save-wrapper .option-name-input {
  width: 250px;
  outline: none;
  border: 0px none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  padding-bottom: 5px;
  font-family: "Lato", sans-serif;
  margin-top: 10px;
  margin-right: 50px;
}
.save-wrapper button {
  float: right;
  border-radius: 5px;
  border: 0px none;
  padding: 10px 10px;
  font-family: "Bree Serif", serif;
  transition: 0.1s all;
  cursor: pointer;
}
.save-wrapper button:hover {
  background-color: rgb(129, 176, 192);
  transition: 0.1s all;
  color: #fff;
}
.save-wrapper .checkbox-label {
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
  color: #aaa;
  vertical-align: top;
  margin-top: 3px;
}
.save-wrapper .container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: top;
  margin-top: 6px;
}
.save-wrapper .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.save-wrapper .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 5px;
}
.save-wrapper .container:hover input ~ .checkmark {
  background-color: #ccc;
  transition: 0.2s all;
}
.save-wrapper .container input:checked ~ .checkmark {
  background-color: rgb(129, 176, 192);
  transition: 0.2s all;
}
.save-wrapper .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.save-wrapper .container input:checked ~ .checkmark:after {
  display: block;
}
.save-wrapper .container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.save-wrapper ::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #ccc;
  opacity: 1;
}
.save-wrapper ::placeholder { /* Most modern browsers support this now. */
  color: #ccc;
}/*# sourceMappingURL=SaveOption.css.map */