.slider-wrapper {
  margin-bottom: 20px;
}
.slider-wrapper .slider-title {
  font-size: 14px;
  margin-bottom: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.slider-wrapper .slider {
  width: 70%;
  display: inline-block;
}
.slider-wrapper .slider .single-thumb .range-slider__thumb[data-lower] {
  width: 0;
}
.slider-wrapper .slider .single-thumb .range-slider__thumb[data-upper] {
  width: 30px;
  height: 17px;
  border-radius: 7px;
  background-color: #888;
  transition: background-color 0.2s;
}
.slider-wrapper .slider .single-thumb .range-slider__range {
  background-color: #ddd;
  height: 5px;
}
.slider-wrapper .slider .single-thumb .range-slider__thumb[data-upper]:hover {
  background-color: rgb(129, 176, 192);
  transition: background-color 0.2s;
}
.slider-wrapper .value {
  float: right;
}
.slider-wrapper .value {
  width: 40px;
  outline: none;
  border: 0px none;
  border-bottom: 1px solid #ccc;
  font-size: 12px;
  padding-bottom: 5px;
  font-family: "Lato", sans-serif;
  text-align: right;
}

.range-slider {
  background-color: #eee;
}/*# sourceMappingURL=Slider.css.map */