.individual-input-wrap .input-title {
  color: #333;
  font-size: 1em;
  margin-top: 20px;
}
.individual-input-wrap .input-field {
  width: 250px;
  outline: none;
  border: 0px none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  padding: 8px 8px 10px 8px;
  font-family: "Lato", sans-serif;
  margin-top: 10px;
  margin-right: 50px;
}/*# sourceMappingURL=ConfigInput.css.map */