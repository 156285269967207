@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@font-face {
  font-family: "Grueber";
  src: url("Grueber_Rg.ttf");
}
body {
  font-family: "Lato", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../../public/media/splash.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-attachment: fixed;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.content-splash {
  width: 100%;
  height: 100%;
  position: fixed;
  padding: 0px;
  margin: 0px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../public/media/splash.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.content-splash .login-wrapper {
  position: fixed;
  left: 70px;
  top: 150px;
  height: 400px;
  width: 250px;
  background-color: #efefef;
  border-radius: 15px 15px 0px 0px;
  padding: 20px 30px 30px 30px;
  box-shadow: 15px 15px 30px 0px rgba(0, 0, 0, 0.37);
}
.content-splash .login-wrapper .bottom {
  background-color: rgb(52, 51, 52);
  width: 100%;
  padding: 20px 30px 30px 30px;
  height: 50px;
  left: -30px;
  position: relative;
  border-radius: 0px 0px 15px 15px;
}
.content-splash .login-wrapper .title {
  font-family: "Grueber", serif;
  font-size: 40px;
  margin: 0px;
  margin-top: 20px;
}
.content-splash .login-wrapper .desc {
  font-size: 0.9em;
  text-align: left;
  margin-bottom: 45px;
}
.content-splash .login-wrapper .subtitle {
  margin-bottom: 3px;
  margin-top: 15px;
  font-family: "Lato", sans-serif;
  font-size: 0.9em;
  color: #333;
}
.content-splash .login-wrapper h3 {
  font-family: "Lato", sans-serif;
  font-size: 1.2em;
}
.content-splash .login-wrapper .button-wrap {
  text-align: center;
  margin: 30px auto;
  width: 100px;
}
.content-splash .login-wrapper .button-wrap button {
  border-radius: 5px;
  font-size: 15px;
  border: 0px none;
  padding: 10px 10px;
  font-family: "Bree Serif", serif;
  transition: 0.1s all;
  display: block;
  margin-bottom: 8px;
  width: 100px;
  background-color: #ddd;
  cursor: pointer;
}
.content-splash .login-wrapper .button-wrap button:hover {
  background-color: rgb(129, 176, 192);
  transition: 0.1s all;
  color: #fff;
}
.content-splash .login-wrapper .input-wrap {
  width: 90%;
}
.content-splash .login-wrapper input {
  width: 90%;
  background: #ddd;
  height: 20px;
  padding: 10px 10px;
  border-radius: 10px;
  outline: none;
  border: 0px none;
  font-size: 0.9em;
}
.content-splash .login-wrapper input:active {
  outline: none;
}
.content-splash .login-wrapper .HL-logo {
  margin-top: 10px;
  margin-left: 50px;
}
.content-splash .message {
  position: fixed;
  left: 70px;
  top: 150px;
  height: 450px;
  width: 250px;
  background-color: #efefef;
  border-radius: 15px;
  padding: 20px 30px 30px 30px;
  box-shadow: 15px 15px 30px 0px rgba(0, 0, 0, 0.37);
}
.content-splash .message .text {
  font-size: 1em;
  color: #333;
  margin: 30px 0px 0px 0px;
}

.background .wrapper {
  width: 1000px;
  min-height: 100vh;
  margin: 0px auto 0px auto;
  background-color: #f7f7f7;
  padding: 120px 100px 80px 100px;
}
.background .wrapper .title {
  font-family: "Grueber", serif;
  font-size: 3em;
  margin: 0px;
  color: rgb(52, 51, 52);
}
.background .wrapper .subtitle {
  font-family: "Grueber", serif;
  font-size: 2em;
  color: #888;
  margin: 0px;
}
.background .wrapper .project-description {
  font-size: 1.2em;
  color: #555;
}
.background .wrapper .help-text {
  font-size: 14px;
  color: #777;
  margin: 0px;
  margin-bottom: 0px;
  max-width: 400px;
}
.background .wrapper .section-title {
  font-family: "Grueber", serif;
  font-size: 1.2em;
  color: #333;
  margin-bottom: 10px;
}
.background .wrapper .legend-wrap {
  margin-top: 20px;
  width: calc(100% - 40px);
  position: relative;
}
.background .about .subtitle {
  color: #333;
  font-size: 1.5em;
  margin-top: 30px;
  margin-bottom: 15px;
}
.background .about .main-text {
  line-height: 1.4;
  width: 750px;
}
.background .about .about-text {
  line-height: 1.8;
  margin-bottom: 50px;
}
.background .glossary .subtitle {
  color: #333;
  font-size: 1.5em;
  margin-top: 50px;
  margin-bottom: 0px;
}
.background .glossary .source {
  color: #999;
  font-size: 0.9em;
  margin-top: 0px;
  margin-bottom: 5px;
}
.background .glossary .main-text {
  line-height: 1.4;
  width: 750px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.background .glossary a {
  text-decoration: none;
  color: #999;
}
.background .glossary a:hover {
  color: #333;
}
.background .configuration .warning {
  background-color: rgb(219, 108, 108);
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 30px;
  text-align: center;
}
.background .configuration .warning .warning-title {
  font-size: 25px;
  font-family: "Grueber", serif;
}
.background .configuration .warning .warning-text {
  margin: 10px auto;
  width: 400px;
}
.background .configuration .subtitle {
  color: #333;
  font-size: 1.5em;
  margin-top: 20px;
}
.background .configuration button {
  border-radius: 5px;
  font-size: 15px;
  border: 0px none;
  padding: 10px 10px;
  font-family: "Bree Serif", serif;
  transition: 0.1s all;
  display: block;
  margin-bottom: 8px;
  width: 150px;
  background-color: rgb(230, 230, 230);
  transition: 0.2s all;
  cursor: pointer;
}
.background .configuration button:hover {
  background-color: rgb(129, 176, 192);
  transition: 0.1s all;
  color: #fff;
}
.background .configuration .normal {
  background-color: rgb(230, 230, 230);
  transition: 0.2s all;
}
.background .configuration .activated {
  background-color: rgb(132, 211, 108);
  transition: 0.2s all;
}
.background .configuration .activated:hover {
  background-color: rgb(132, 211, 108);
  color: #333;
  transition: 0.2s all;
}
.background .configuration .download-wrapper {
  margin: 40px auto 10px auto;
  width: 150px;
}
.background .configuration .button-wrapper {
  float: right;
}
.background .configuration .input-wrapper {
  margin-bottom: 50px;
  display: inline-block;
  vertical-align: top;
}
.background .configuration .input-wrapper .input-col {
  display: inline-block;
}
.background .configuration .dropdown {
  margin-top: 10px;
  font-size: 15px;
  border-radius: 5px;
  border: 0px none;
  width: 265px;
  margin-bottom: 20px;
}
.background .configuration .kpi-wrapper {
  display: inline-block;
  margin-bottom: 50px;
}
.background .plot-card-wrapper {
  margin-top: 50px;
  display: grid;
  grid-template-columns: auto auto auto;
}
.background .plot-title {
  font-size: 35px;
  font-family: "Grueber", serif;
  margin: 70px 0px 0px 0px;
}
.background .plot-table-wrap {
  margin-top: 10px;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
}
.background .plot-table-wrap .cell {
  margin: 1px;
  vertical-align: top;
  text-align: center;
  height: 40px;
  width: 80px;
  padding: 10px 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  transition: background-color 0.3s;
}
.background .plot-table-wrap .first-column {
  text-align: left;
  justify-content: left;
  width: 150px;
  font-weight: 600;
}
.background .plot-table-wrap .header-row {
  display: inline-block;
}
.background .plot-table-wrap .header-row .header-cell {
  background-color: #ddd;
  color: #333;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  height: 60px;
}
.background .plot-table-wrap .content-row {
  height: 53px;
  overflow: hidden;
  display: flex;
  vertical-align: top;
  font-size: 15px;
  margin: 0px 0px;
  transition: margin 0.2s;
}
.background .plot-table-wrap .content-row .cell {
  height: 30px;
  background-color: #fff;
  position: relative;
}
.background .plot-table-wrap .content-row .cell .arrow {
  float: right;
  border-right: 1px solid #555;
  border-top: 1px solid #555;
  width: 7px;
  height: 7px;
  top: 24px;
  right: 35px;
  position: absolute;
  transform: rotateY(0deg) rotate(45deg) translateY(-50%);
  transition: 0.2s all;
}
.background .plot-table-wrap .content-row .cell .indicator {
  width: 10px;
  height: 100%;
  position: absolute;
  left: 0px;
}
.background .plot-table-wrap .content-row .cell:hover .arrow {
  right: 25px;
}
.background .plot-table-wrap .content-row .update {
  font-size: 13px;
  color: #999;
}
.background .plot-table-wrap .content-row a {
  color: #333;
}
.background .plot-table-wrap .content-row:hover .cell {
  background-color: #eee !important;
  transition: background-color 0.2s;
}
.background .plot-table-wrap .break-row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: #fff;
  margin: 1px 0px 2px 0px;
  border-radius: 3px;
  font-weight: 600;
}
.background .dashboard {
  position: relative;
}
.background .dashboard .section-title {
  font-family: "Grueber", serif;
  font-size: 1.5em;
  color: #333;
}
.background .dashboard .meta-info-wrap {
  margin: 20px 0px 30px 0px;
}
.background .dashboard .meta-info-wrap .meta-col {
  display: inline-block;
  margin-right: 50px;
}
.background .dashboard .meta-info-wrap .meta-title {
  margin: 0px;
  font-size: 14px;
  color: #aaa;
  position: relative;
}
.background .dashboard .meta-info-wrap .meta-info {
  margin: 10px 0px 0px 0px;
  font-size: 20px;
  font-family: "Grueber", serif;
  color: #555;
}
.background .dashboard .showTargets-toggle {
  float: right;
  position: absolute;
  top: 30px;
  right: 20px;
}
.background .dashboard .pill-header-wrap {
  max-width: 400px;
}
.background .dashboard .pill-header-wrap .pill-header {
  font-family: "Bree Serif", serif;
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #333;
}
.background .dashboard .pill-header-wrap .desc {
  font-size: 14px;
  color: #777;
  margin: 0px;
  margin-bottom: 25px;
  max-width: 700px;
}
.background .dashboard .status-pills {
  display: grid;
  grid-template-columns: auto auto auto;
  -moz-column-gap: 18px;
       column-gap: 18px;
  margin-top: 20px;
}
.background .dashboard .table-wrapper, .background .dashboard .plot-budget-wrapper {
  width: 940px;
  padding: 30px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  margin-bottom: 15px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
}
.background .dashboard .table-wrapper .options-table-title, .background .dashboard .plot-budget-wrapper .options-table-title {
  font-family: "Bree Serif", serif;
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #333;
}
.background .dashboard .table-wrapper .desc, .background .dashboard .plot-budget-wrapper .desc {
  font-size: 14px;
  color: #999;
  margin: 0px;
  margin-bottom: 25px;
}
.background .dashboard .table-wrapper .plot-comparison-chart, .background .dashboard .plot-budget-wrapper .plot-comparison-chart {
  height: 160px;
}
.background .dashboard .plot-budget-wrapper {
  margin-bottom: 50px;
  width: 940px;
}
.background .dashboard .plot-budget-wrapper .desc {
  font-size: 14px;
  color: #999;
  margin: 0px;
}
.background .dashboard .calculator .sidebar {
  max-height: 85vh;
  overflow-y: auto;
  width: 360px;
  padding: 0px 0px;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
  background-color: #fff;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
  position: sticky;
  display: inline-block;
  top: 5rem;
}
.background .dashboard .calculator .sidebar .subsection {
  max-height: 800px;
  overflow: hidden;
  padding: 10px 30px 0px 30px;
  top: -2px;
  position: relative;
  border-top: 1px #ddd solid;
  transition: 0.3s all;
}
.background .dashboard .calculator .sidebar .subsection .title-row {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  padding: 10px 0px 20px 0px;
}
.background .dashboard .calculator .sidebar .subsection .title-row .sidebar-title {
  font-family: "Bree Serif", serif;
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #333;
}
.background .dashboard .calculator .sidebar .subsection .title-row .arrow {
  float: right;
  border-right: 2px solid #555;
  border-top: 2px solid #555;
  width: 7px;
  height: 7px;
  top: -27px;
  right: 15px;
  position: relative;
  transform: rotateY(0deg) rotate(-45deg) translateY(-50%);
  transition: 0.2s all;
}
.background .dashboard .calculator .sidebar .subsection .sidebar-subtitle {
  font-size: 13px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #666;
}
.background .dashboard .calculator .sidebar .subsection .sidebar-description {
  font-size: 13px;
  color: #999;
  margin-bottom: 20px;
}
.background .dashboard .calculator .sidebar .subsection .slider-section {
  margin-bottom: 30px;
}
.background .dashboard .calculator .sidebar .subsection.closed {
  max-height: 60px;
  overflow: hidden;
  transition: 0.3s all;
}
.background .dashboard .calculator .sidebar .subsection.closed .title-row .arrow {
  top: -38px;
  right: 20px;
  transform: rotateY(0deg) rotate(135deg) translateY(-50%);
  transition: 0.2s all;
}
.background .dashboard .calculator .results-wrap {
  width: 620px;
  height: auto;
  vertical-align: top;
  display: inline-block;
}
.background .dashboard .calculator .results-wrap .results-pill {
  vertical-align: top;
  position: relative;
  width: 560px;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
  display: inline-block;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  margin-left: 15px;
  margin-bottom: 15px;
}
.background .dashboard .calculator .results-wrap .results-pill .results-pill-title {
  font-family: "Bree Serif", serif;
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #333;
}
.background .dashboard .calculator .results-wrap .results-pill .desc {
  font-size: 14px;
  color: #999;
  margin: 0px;
  margin-bottom: 25px;
}
.background .dashboard .calculator .results-wrap .results-pill .budget-chart-wrap {
  height: 250px;
}
.background .dashboard .calculator .results-wrap .results-pill .graph-year-wrap {
  float: right;
  right: 40px;
  top: 20px;
  position: absolute;
  color: #ccc;
}
.background .dashboard .calculator .results-wrap .results-pill .graph-year-wrap .year-tag {
  display: inline-block;
  margin-right: 5px;
}
.background .dashboard .calculator .results-wrap .results-pill .graph-year-wrap .currency {
  display: inline-block;
  color: #999;
}
.background .dashboard .calculator .results-wrap .results-pill .apexcharts-data-labels > text {
  font-family: "Lato", serif !important;
  color: #333 !important;
  font-weight: 400 !important;
  font-size: 13px;
}
.background .dashboard .calculator .results-wrap .results-pill.touching {
  margin-bottom: 3px !important;
}
.background .dashboard .calculator .results-wrap .results-pill-wrap {
  vertical-align: top;
  width: 600px;
  margin-left: 15px;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: auto auto auto;
}
.background .dashboard .calculator .results-wrap .results-pill-wrap .grey {
  background-color: #f8f8f8 !important;
  color: #999 !important;
}
.background .dashboard .calculator .results-wrap .results-pill-wrap .mini-results-pill {
  padding: 20px 40px;
  width: 125px;
  height: 100px;
  margin-right: 3px;
  vertical-align: top;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.background .dashboard .calculator .results-wrap .results-pill-wrap .mini-results-pill p {
  margin: 0px;
}
.background .dashboard .calculator .results-wrap .results-pill-wrap .mini-results-pill .subtitle {
  font-size: 12px;
  margin-bottom: 5px;
  color: #999;
  border-bottom: 1px solid #ddd;
}
.background .dashboard .calculator .results-wrap .results-pill-wrap .mini-results-pill .currency {
  text-align: right;
  margin-bottom: 10px;
}
.background .dashboard .calculator .results-wrap .results-pill-wrap .mini-results-pill .main {
  font-size: 1.5em;
  margin-top: 20px;
  font-weight: bold;
}
.background .dashboard .calculator .results-wrap .results-pill-wrap .mini-results-pill .lower {
  margin-top: 17px;
}/*# sourceMappingURL=main-style.css.map */