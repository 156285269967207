.help-container{
    .help-icon{
        position: relative;
        cursor: pointer;
        display: inline-block;
        // width:15px;
        // height:15px;
        padding:3px 6px;
        border-radius: 10px;
        font-size:10px;
        border:1px solid #ddd;
        background-color: #fff;
        z-index:1;
    }
    .help-icon:hover{
        background-color: #ddd;
    }
    
    .help-window{
        font-family:'Lato', serif;
        display:block;
        float:right;
        position: absolute;
        width:250px;
        // height:300px;
        padding:20px;
        background-color: #fff;
        top:20px;
        left:20px;
        z-index:2;
        border-radius:5px;
        box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
        opacity:1;
        transition: opacity .2s;
        .help-title{
            font-size:18px;
            margin-bottom:20px;
        }
        .help-subtitle{
            font-size: 12px;
            color:#999;
            margin:0px 0px 0px 0px;
        }
        .help-confidence{
            font-size: 18px;
        }
    }

    .help-window.hidden{
        display:none;
        opacity:0;
        transition: opacity .2s;
    }
}