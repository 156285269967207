.individual-input-wrap{
    .input-title{
        color:#333;
        font-size:14px;
        margin-top:20px;
    }
    .input-field{
        border-bottom:1px solid #ccc;
        font-size:14px;
        margin-top: 5px;
    }
}
