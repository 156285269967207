.options-table-wrapper{
    $cellWidth:80px;
    $cellHeight:40px;
    $cellPadding:10px 15px;
    margin-top:30px;    
    $longTrans: .2s;
    $shortTrans: .2s;
    .cell{
        margin:1px;
        vertical-align: top;
        text-align: center;
        height:$cellHeight;
        width: $cellWidth;
        padding:$cellPadding;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
    }
    .first-column{
        text-align: left;
        justify-content: left;
        width:100px;
    }
    .header-row{
        .header-cell{
            background-color: #ddd;
            color:#333;
            font-size:12px;
            font-family: 'Lato', sans-serif;
            font-weight: 600;
            height:$cellHeight+10px;
        }
    }
    .content-row{
        height:53px;
        overflow:hidden;
        display: inline-block;
        vertical-align: top;
        .default{
            color:#888;
            margin-left: 5px;
        }
        .content-cell{
            background-color: #eee;
            font-size:14px;
            height:30px;
        }
        .results{
            display: inline-block;
            font-size:15px;
            vertical-align: top;
            .cell{
                height:30px;
                position:relative;
                background-color: #f6f6f6;
                .indicator{
                    position: absolute;
                    width:10px;
                    left:0px;
                    height:calc(100%)
                }
            }
        }
        
        
    }

    .edit-wrap{
        display: inline-block;
        vertical-align: top;
        position:relative;
        z-index: 1;
        .edit-cell{
            font-family: 'Bree Serif', serif;
            background-color: #ddd;
            font-size:12px;
            width:30px;
            height:30px;
            transition: .1s all;
            cursor: pointer;
        }
        
        .button-column{
            // max-width: 130px;
            max-height:350px;
            padding:10px 10px 2px 10px;
            overflow:show;
            opacity:1;
            transition: padding $longTrans, max-height $longTrans, top $longTrans, right $longTrans ;
            transition: opacity $longTrans, overflow $longTrans;
            float: right;
            position:absolute;
            top:-40px;
            right:60px;
            border-radius:5px;
            background-color: #fff;
            box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
            button{
                border-radius:5px;
                border:0px none;
                padding:10px 10px;
                font-family: 'Bree Serif', serif;
                transition: .1s all;
                display: block;
                margin-bottom:8px;
                width:100px;
                cursor: pointer;
            }
            .delete:hover{
                // background-color: rgb(208,46,111);
                background-color: rgb(52,51,52);
                color:#eee;
            }
            button:hover{
                background-color: rgb(129,176,192);
                transition: .1s all;
                color:#fff;
            }
        }
    }
    .edit-wrap.closed{
        .edit-cell{
            background-color: #eee;
            transition: .1s all;
        }
        .button-column{
            max-height:0px;
            top:-50px;
            // max-width:0px;
            overflow: hidden;
            opacity:0;
            transition: padding $longTrans, max-height $longTrans, top $longTrans, right $longTrans ;
            transition: opacity $longTrans, overflow $longTrans;
            // left:70px;
            // right:60px;
            padding:0px 10px 0px 10px;
        }
    }
}