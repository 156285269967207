.kpi-chart-wrap {
  height: 80px;
  margin-bottom: 20px;
  width: 600px;
}
.kpi-chart-wrap .kpi-chart-label {
  display: inline-block;
  width: 80px;
  vertical-align: top;
  margin-top: 20px;
  margin-right: 40px;
  font-size: 0.9em;
}
.kpi-chart-wrap .kpi-chart {
  display: inline-block;
  width: 400px;
  height: 80px;
}/*# sourceMappingURL=KPIChart.css.map */